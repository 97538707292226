import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../components/title"
import Hero from "../components/hero"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
	grid: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(10),
	},
	item: {},
	link: {
		textDecoration: 'none !important'
	},
	name: {
		color: theme.palette.text.secondary,
		display: "flex",
		textAlign: "center",
		marginTop: theme.spacing(2.5),
		fontWeight: 400,
		justifyContent: "center"
	},
	imageContainer: {
		display: "block",
		overflow: "hidden",
	},
	image: {
		backgroundColor: theme.palette.common.white,
	}
}))

const AllSaddles = ({ data }) => {
	const classes = useStyles()

	const {
		datoCmsHeroImage,
		datoCmsCategorySaddle = {}
	} = data || {}

	const { title, image } = datoCmsHeroImage || {}
	const categorySaddle = (datoCmsCategorySaddle && datoCmsCategorySaddle.categoryType) || {}

	return (
		<Layout>
			<Hero title={title} image={image} />
			<Container maxWidth="lg">
				<Box mt={10} display="flex" justifyContent="center">
					<Title>{title}</Title>
				</Box>
				<Grid container spacing={8} className={classes.grid}>
					{categorySaddle.map(({ id, category, image, slug, title }) => (
						<Grid item md={4} sm={6} xs={12} key={id} className={classes.item}>
							<Link to={`/siodla/${slug}`} className={classes.link}>
								{image && (
									<div className={classes.imageContainer}>
										<GatsbyImage image={getImage(image)} alt={title} className={classes.image} />
									</div>
								)}
								<Typography variant="h5" className={classes.name}>{title}</Typography>
							</Link>
						</Grid>
					))}
				</Grid>
			</Container>
		</Layout>
	)
}

export const query = graphql`
  query queryAllSaddles {
    allDatoCmsPageSaddle(sort: {fields: position, order: ASC}) {
      nodes {
        id
        slug
        saddleType
        saddleName
        saddleImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
		datoCmsHeroImage(pageType: { eq: "siodla" }) {
			title
			image {
				gatsbyImageData(width: 960)
			}
		}
		datoCmsCategorySaddle {
			categoryType {
				id
				title
				slug
				category
				image {
					gatsbyImageData(
						layout: CONSTRAINED
						aspectRatio: 1
						width: 500
						height: 500
						placeholder: DOMINANT_COLOR
					)
				}
			}
		}
  }
`

export default AllSaddles
